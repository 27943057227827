import * as React from 'react';

import creativity from '../sbAssets/assets/img/illustrations/creativity.svg';
import AnimatedSvgImage from './animatedSvgImage';
import FeatherIcon from 'feather-icons-react';

const TextHeader = ({ desc, title, bottomSvgClasses }) => {
  return (
    <header className="page-header-ui page-header-ui-dark bg-gradient-primary-to-secondary">
      <div className="page-header-ui-content pt-10">
        <div className="container px-5 text-center">
          <div className="row gx-5 justify-content-center">
            <div className="col-lg-8">
              <h1 className="page-header-ui-title mb-3">{title}</h1>
              <p className="page-header-ui-text">{desc}</p>
            </div>
          </div>
        </div>
      </div>
      <div
        className={`svg-border-rounded ${
          bottomSvgClasses ? bottomSvgClasses : 'text-white'
        }`}
      >
        {/* Rounded SVG Border*/}
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 144.54 17.34"
          preserveAspectRatio="none"
          fill="currentColor"
        >
          <path d="M144.54,17.34H0V0H144.54ZM0,0S32.36,17.34,72.27,17.34,144.54,0,144.54,0" />
        </svg>
      </div>
    </header>
  );
};

export default TextHeader;
